import Dialog from "@/ui/Dialog";
import { communityTypes } from "@/utils/lists";
import Button from "@/ui/Button";

const updateTypes = (types, key, value) => {
    let output = [];

    if (types.includes(key)) {
        output.push(value);
    }

    return output;
};

// function to change 10 digit phone number to formated number
export const formatPhoneNumber = (phoneNumberString) => {
    let cleaned = ("" + phoneNumberString).replace(/\D/g, "");

    let num =
        "(" +
        cleaned.substring(0, 3) +
        ")" +
        " " +
        cleaned.substring(3, 6) +
        "-" +
        cleaned.substring(6, 10);

    if (cleaned.length > 10) {
        num += " x" + cleaned.substring(10);
    }

    return num;
};

export const getCommunityTypes = (facilityType, delimiter = "|", MAX_LENGTH = 10) => {
    let output = [];

    if (facilityType) {
        const types = facilityType.split(";");

        output = [...output, ...updateTypes(types, "IL", "Independent")];
        output = [...output, ...updateTypes(types, "AL", "Assisted")];
        output = [...output, ...updateTypes(types, "MC", "Memory")];
        //output = [...output, ...updateTypes(types, "ALW", "ALW")];
        output = [...output, ...updateTypes(types, "Hospice", "Hospice")];
        output = [...output, ...updateTypes(types, "Respite", "Respite")];
        output = [...output, ...updateTypes(types, "RCFE", "RCFE")];
        output = [...output, ...updateTypes(types, "CCRC", "CCRC")];
        output = [...output, ...updateTypes(types, "NH", "Nursing Home")];
        output = [...output, ...updateTypes(types, "Medicare", "Medicare")];
        output = [...output, ...updateTypes(types, "Medicaid", "ALW/Medi-Cal")];
        output = [...output, ...updateTypes(types, "Medicaid2", "Medicaid")];
        //output = [...output, ...updateTypes(types, "Medi-Cal", "Medi-Cal")];
        output = [...output, ...updateTypes(types, "LTC", "LTC")];
        output = [...output, ...updateTypes(types, "Private Pay", "Private Pay")];
        output = [
            ...output,
            ...updateTypes(types, "Managed Care Contracts", "Managed Care/Medi-Cal"),
        ];
        output = [
            ...output,
            ...updateTypes(types, "Residential Care Home", "Residential Care Home"),
        ];
        output = [...output, ...updateTypes(types, "IHCS", "In-Home Care Services")];
        output = [...output, ...updateTypes(types, "IHHC", "In-Home Health Care")];
        output = [...output, ...updateTypes(types, "Skilled Nursing", "Skilled Nursing")];
        output = [
            ...output,
            ...updateTypes(types, "Rehabilitation Facility", "Rehabilitation Facility"),
        ];
        output = [
            ...output,
            ...updateTypes(types, "Retirement Living", "Retirement Living"),
        ];
        output = [
            ...output,
            ...updateTypes(types, "Personal Care Home", "Personal Care Home"),
        ];
        output = [
            ...output,
            ...updateTypes(types, "Board and Care Home", "Board and Care Home"),
        ];
        output = [
            ...output,
            ...updateTypes(types, "Adult Foster Care Home", "Adult Foster Care Home"),
        ];
        output = [...output, ...updateTypes(types, "Adult Group Home", "Adult Group Home")];
    }

    if (delimiter === "&bull;") {
        return output.map((out, index) => (
            <span key={index}>
                {out}
                {index !== output.length - 1 ? <span className="px-2">&bull;</span> : null}
            </span>
        ));
    }

    return output
        .filter((_, i) => i <= MAX_LENGTH)
        .map((out, index) => (
            <span key={index}>
                {out}
                {index !== output.length - 1 && index !== MAX_LENGTH ? (
                    <span className="px-2">{delimiter}</span>
                ) : null}
                {index === MAX_LENGTH ? <span className="px-2">. . .</span> : null}
            </span>
        ));
};

export const getCommunityTypesText = (facilityType) => {
    let output = [];

    if (facilityType) {
        const types = facilityType.split(";");

        output = [...output, ...updateTypes(types, "IL", "Independent")];
        output = [...output, ...updateTypes(types, "AL", "Assisted")];
        output = [...output, ...updateTypes(types, "MC", "Memory")];
        //output = [...output, ...updateTypes(types, "ALW", "ALW")];
        output = [...output, ...updateTypes(types, "Hospice", "Hospice")];
        output = [...output, ...updateTypes(types, "Respite", "Respite")];
        output = [...output, ...updateTypes(types, "RCFE", "RCFE")];
        output = [...output, ...updateTypes(types, "CCRC", "CCRC")];
        output = [...output, ...updateTypes(types, "NH", "Nursing Home")];
        output = [...output, ...updateTypes(types, "Medicare", "Medicare")];
        output = [...output, ...updateTypes(types, "Medicaid", "Medicaid (Medi-Cal)")];
        //output = [...output, ...updateTypes(types, "Medi-Cal", "Medi-Cal")];
        output = [...output, ...updateTypes(types, "LTC", "LTC")];
        output = [...output, ...updateTypes(types, "Private Pay", "Private Pay")];
        output = [
            ...output,
            ...updateTypes(types, "Managed Care Contracts", "Managed Care Contracts"),
        ];
        output = [
            ...output,
            ...updateTypes(types, "Residential Care Home", "Residential Care Home"),
        ];
        output = [...output, ...updateTypes(types, "IHCS", "In-Home Care Services")];
        output = [...output, ...updateTypes(types, "Skilled Nursing", "Skilled Nursing")];
        output = [
            ...output,
            ...updateTypes(types, "Rehabilitation Facility", "Rehabilitation Facility"),
        ];
        output = [
            ...output,
            ...updateTypes(types, "Retirement Living", "Retirement Living"),
        ];
        output = [
            ...output,
            ...updateTypes(types, "Personal Care Home", "Personal Care Home"),
        ];
        output = [
            ...output,
            ...updateTypes(types, "Board and Care Home", "Board and Care Home"),
        ];
        output = [
            ...output,
            ...updateTypes(types, "Adult Foster Care Home", "Adult Foster Care Home"),
        ];
        output = [...output, ...updateTypes(types, "Adult Group Home", "Adult Group Home")];
    }

    return output.join(" | ");
};

export const CommunityTypesDialog = ({ open, onClose }) => {
    return (
        <div className="">
            <Dialog
                open={open}
                onClose={onClose}
                className="fixed z-40 top-24 bottom-6 left-16 right-16"
            >
                <div className="flex items-center justify-center">
                    <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />

                    <div className="z-50 px-16 py-8 bg-white max-w-3xl border rounded-xl text-left">
                        <div className="max-h-[400px] overflow-y-scroll">
                            <h3 className="text-2xl text-primary-500 font-headline font-bold">
                                Community Types
                            </h3>

                            <div className="mt-4">
                                {communityTypes.map((c) => (
                                    <div key={c.name} className="mt-4">
                                        <h4 className="text-base text-primary-500 font-bold">
                                            {c.name}
                                        </h4>
                                        <p>{c.help}</p>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="mt-4 flex justify-end">
                            <Button onClick={onClose}>Close</Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export const CommunityLicenseNumberDialog = ({ open, onClose }) => {
    return (
        <div className="">
            <Dialog
                open={open}
                onClose={onClose}
                className="fixed z-40 top-36 bottom-6 left-16 right-16 overflow-y-auto"
            >
                <div className="flex items-center justify-center">
                    <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />

                    <div className="z-50 px-16 py-8 bg-white max-w-2xl border rounded-xl text-left">
                        <div>
                            <h3 className="text-2xl text-primary-500 font-headline font-bold">
                                Community License Number
                            </h3>

                            <p className="mt-8 text-lg">
                                Each state has regulations in place for communities that
                                provide assisted living. Most state licensing agencies
                                provide online access to community inspections, audits, and
                                history of citations and allegations. Some states require
                                you to call, mail, or email a request for a background check
                                on a community. Look at our{" "}
                                <a
                                    href="/resources/licensing-by-state"
                                    className="text-primary-500 underline hover:font-bold"
                                >
                                    resource tool
                                </a>{" "}
                                with links to all state licensing agencies. This will help
                                you learn if a community is in good standing and shows a
                                record of offering quality services.
                            </p>
                        </div>

                        <div className="mt-8 flex justify-end">
                            <Button onClick={onClose}>Close</Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};
